import {
  getPlace,
  GetPlaceParamsType,
} from '@vangst/services/google/maps.client'
import { HUBSPOT_SUBJECTS } from '@vangst/services/hubspot'
import { PageType } from '@vangst/services/oogst/page'
import {
  ElasticSearchEndpoints,
  ElasticSearchVars,
} from '@vangst/services/oogst/search/useElasticSearch'
import useViewer from '@vangst/services/oogst/viewer/useViewer'
import { useState } from 'react'
import { MdError } from 'react-icons/md'
import AlertText from '../../components/feedback/AlertText'
import ClickyLink from '../../components/navigation/ClickyLink'
import PageHeader from '../../components/views/PageHeader'
import useSelectView from '../application/useSelectView'
import DialogContact from '../job/feedback/DialogContact'
import { AsTypeUnion, AtTypeUnion } from '../nodes/RendererFactory'
import SearchFilters from './forms/SearchFilters'
import SearchPlace from './forms/SearchPlace'
import SearchTerm from './forms/SearchTerm'
import SearchRenderer from './SearchRenderer'
import useQueryParams from './useQueryParams'
import useRouterControl from './useRouterControl'

type PropsType = {
  readonly as?: AsTypeUnion
  readonly at?: AtTypeUnion
  readonly address?: string
  readonly endpoint: ElasticSearchEndpoints
  readonly page?: PageType | null
  readonly showMarkdownContent?: boolean
  readonly variables?: ElasticSearchVars
}

/**
 * A complete search component used at the page level.
 *
 * @example
 * <SearchVoltron
 *   endpoint="searchJobPostings"
 *   page={page}
 *   variables={{ term: 'Vangst' }}
 * />
 */
function SearchVoltron(props: PropsType) {
  const [showContactForm, setShowContactForm] = useState(false)
  const { endpoint, page, showMarkdownContent, variables } = props
  const { getQueryParams } = useQueryParams()
  const { viewer } = useViewer()
  const vars = variables ?? { ...getQueryParams(), first: 24 }
  const { change, register, submit, setValue, getValues } = useRouterControl()

  const isUnverifiedCompany = viewer?.client?.permissions?.readPendingBanner

  const [placeError, setPlaceError] = useState<string | null>(null)

  const { SelectAs, dynamicAs } = useSelectView({
    initialAt: props.at ?? 'infinite',
    initialAs: props.as ?? 'card',
  })

  const setPlace = async (value: GetPlaceParamsType | null) => {
    if (placeError) setPlaceError(null)
    if (!value) {
      setValue('radiusLocation', null)
      return
    }
    try {
      const place = await getPlace(value)
      if (!place) return
      const { lat: latitude, lng: longitude } = place
      const radius = getValues('radiusLocation')?.radius ?? 50
      setValue('radiusLocation', {
        radius: radius.toString(),
        latitude,
        longitude,
      })
      change()
    } catch (error) {
      setPlaceError((error as Error).message)
    }
  }

  // TODO: Move to the server and add Meta
  const category = endpoint.startsWith('searchJobPostings')
    ? 'Jobs'
    : endpoint.startsWith('searchClients')
    ? 'Companies'
    : endpoint.startsWith('searchCandidates')
    ? 'Members'
    : endpoint.startsWith('searchCredentials')
    ? 'Trainings'
    : undefined

  return (
    <form className="mood-white" onSubmit={submit}>
      <PageHeader
        className="md:[&_>_div]:-mb-8"
        icon="search"
        title={page?.title ?? `Search ${category}` ?? 'Search'}
        subtitle={page?.subtitle ?? `Find Cannabis Industry ${category}`}
        description={page?.description}
        markdownContent={
          showMarkdownContent ? page?.markdownContent : undefined
        }
      >
        <div className="flow-y-xs w-full max-w-screen-md sm:flow-y-sm md:flow-x-sm">
          <SearchTerm
            category={category}
            defaultValue={props.variables?.term}
            register={register}
          />
          {endpoint !== 'searchCredentials' && (
            <SearchPlace
              defaultAddress={props.address}
              placeError={placeError}
              setPlace={setPlace}
              setPlaceError={setPlaceError}
            />
          )}
          <button
            className="btn h-11 bg-orange text-white active:bg-orange-medium"
            type="submit"
          >
            Search
          </button>
        </div>
      </PageHeader>

      <SearchFilters
        change={change}
        endpoint={endpoint}
        getValues={getValues}
        setValue={setValue}
        register={register}
        variables={vars}
      />

      {isUnverifiedCompany && (
        <AlertText className="contain-xc h-12 text-red" icon={MdError}>
          You must have a verified account to search for members on the Vangst
          network.{' '}
          <ClickyLink
            className="underline"
            onClick={() => setShowContactForm(true)}
          >
            {' '}
            Contact us to verify your account
          </ClickyLink>
          .
        </AlertText>
      )}
      <div className="contain-xc mt-4 flex justify-end">
        {viewer?.permissions?.internalUser && <SelectAs />}
      </div>
      <SearchRenderer
        as={dynamicAs as AsTypeUnion}
        at="infinite"
        className="contain-xyc"
        classNameList={
          dynamicAs === 'table-row' ? 'flex flex-col' : 'grid-four-up'
        }
        endpoint={endpoint}
        promos={endpoint === 'searchJobPostings'}
        variables={vars}
        register={register}
      />
      {showContactForm && (
        <DialogContact
          email={viewer?.email ?? ''}
          phone={viewer?.phoneNumber ?? ''}
          state={viewer?.location?.state ?? ''}
          firstName={viewer?.firstName ?? ''}
          lastName={viewer?.lastName ?? ''}
          handleCancel={() => setShowContactForm(false)}
          hideDescription={true}
          title="Get Verified"
          hubspotContactSubject={HUBSPOT_SUBJECTS.COMPANY_SUPPORT}
        />
      )}
    </form>
  )
}

export default SearchVoltron
