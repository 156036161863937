import titleize from '@vangst/lib/titleize'
import { useCallback, useState } from 'react'
import { MdCheck } from 'react-icons/md'
import FilterPopover from '../../components/feedback/FilterPopover'
import ClickyLink from '../../components/navigation/ClickyLink'
import { AsTypeUnion, AtTypeUnion, ViewTypeMap } from '../nodes/RendererFactory'
import useParams from './useParams'

type PropsType = {
  readonly initialAt: AtTypeUnion
  readonly initialAs?: AsTypeUnion
}
export default function useSelectView(props: PropsType) {
  const { toggleParams } = useParams()
  const { initialAt, initialAs } = props

  const existingStorage = globalThis.sessionStorage
  const [dynamicAs, setDynamicAs] = useState(
    existingStorage?.as || initialAs || 'card',
  )
  const [dynamicAt, setDynamicAt] = useState(existingStorage?.at || initialAt)

  const handleSelectAt = useCallback(
    (nextAt: AtTypeUnion) => {
      setDynamicAt(nextAt)
      setDynamicAs('table-row')
      toggleParams({ key: 'at', value: nextAt })
      toggleParams({ key: 'as', value: 'card' })
    },
    [toggleParams],
  )

  const handleSelectAs = useCallback(
    (nextAs: AsTypeUnion) => {
      setDynamicAs(nextAs)
      toggleParams({ key: 'as', value: nextAs })
    },
    [toggleParams],
  )

  // TODO: Eventually might want to include all of AtTypeUnion options
  const atOptions = ['infinite', 'lanes']
  const SelectAt = () => {
    return (
      <FilterPopover
        isFiltering={false}
        label={`View In: ${titleize(dynamicAt.split('-')[0])}`}
        className="w-48"
      >
        <div className="flow-y-xs w-full items-start">
          {atOptions.map((option) => (
            <ClickyLink
              className="link-orange text-sm"
              key={option}
              onClick={() => handleSelectAt(option as AtTypeUnion)}
            >
              <span className="flow-x-xs items-center">
                <span className="h-4 w-4">
                  {dynamicAt === option ? (
                    <MdCheck className="h-4 w-4 text-orange-light" />
                  ) : null}
                </span>
                <span>{titleize(option.split('-')[0])}</span>
              </span>
            </ClickyLink>
          ))}
        </div>
      </FilterPopover>
    )
  }

  // TODO: Eventually might want to include all of AtTypeUnion options
  const SelectAs = () => {
    return (
      <FilterPopover
        isFiltering={false}
        label={`View As: ${titleize(dynamicAs.split('-')[0])}`}
        className="w-40"
      >
        <div className="flow-y-xs w-full items-start">
          {ViewTypeMap[dynamicAt as AtTypeUnion].map((option) => (
            <ClickyLink
              className="link-orange text-sm"
              key={option}
              onClick={() => handleSelectAs(option as AsTypeUnion)}
            >
              <span className="flow-x-xs items-center">
                <span className="h-4 w-4">
                  {dynamicAs === option ? (
                    <MdCheck className="h-4 w-4 text-orange-light" />
                  ) : null}
                </span>
                <span>{titleize(option.split('-')[0])}</span>
              </span>
            </ClickyLink>
          ))}
        </div>
      </FilterPopover>
    )
  }

  return { SelectAt, SelectAs, dynamicAs, dynamicAt }
}
